var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-field", {
        attrs: {
          name: "radio",
          label: _vm.title,
          "input-align": "right",
          required: _vm.required,
          rules: _vm.rules
        },
        scopedSlots: _vm._u([
          {
            key: "input",
            fn: function() {
              return [
                _c(
                  "van-radio-group",
                  {
                    attrs: { direction: "horizontal" },
                    on: { change: _vm.onConfirm },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  },
                  _vm._l(_vm.columns, function(group) {
                    return _c(
                      "van-radio",
                      { attrs: { "icon-size": "14px", name: group.value } },
                      [_vm._v(_vm._s(group.value))]
                    )
                  }),
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }