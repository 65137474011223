var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-box" }, [
    _c(
      "div",
      { staticClass: "chat-left" },
      [
        _c("router-link", { attrs: { to: { path: "/chatDetails" } } }, [
          _c("img", {
            attrs: { src: require("../../../src/assets/images/service.png") }
          })
        ])
      ],
      1
    ),
    _c("div", { staticClass: "chat-right" }, [
      _c("span", { staticClass: "chat" }, [
        _vm.content && _vm.content != ""
          ? _c("div", { staticClass: "choice-title" }, [
              _vm._v(_vm._s(_vm.content))
            ])
          : _vm._e(),
        !_vm.show
          ? _c(
              "div",
              { staticClass: "choice-list" },
              _vm._l(_vm.extra.choices, function(item) {
                return _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        return _vm.onChoice(item[0])
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item[0]) + " ")]
                )
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "choice-list" },
              _vm._l(_vm.extra.choices, function(item) {
                return _c("div", { staticStyle: { color: "#ccc" } }, [
                  _vm._v(" " + _vm._s(item[0]) + " ")
                ])
              }),
              0
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }