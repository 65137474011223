<template>
  <div>
    <van-field
        readonly
        clickable
        name="picker"
        :value="value"
        :label="title"
        placeholder="请选择"
        @click="showPicker = true"
        input-align="right"
        :required="required"
        :rules= rules
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
          show-toolbar
          value-key="value"
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Form ,Field,Popup} from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.use(Popup);
export default {
  name: 'popupshow',
  data() {
    return {
      value:"",
      showPicker:false,
      rules:[]
    }
  },
  components: {

  },
  props: {
    title: {
      type: String,
      default: '',
    },
    field:{
      type: String,
      default: '',
    },
    columns: '',
    val:null,
    required:{
      type:Boolean,
      default:false
    }
  },
  computed: {
  },
  watch: {

  },
  created() {

  },
  mounted() {
    this.value = this.val
    if(this.required){
      this.rules =[
        {
          required:true,
          trigger:'change',
          message: this.title+'不能为空'
        },

      ]
    }
  },
  methods: {
    onConfirm(val){
      this.value = val.value;
      this.showPicker =false
      this.$emit('getVanfield',this.field,this.value)
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
<style>
.van-field__error-message{
  text-align: right;
}
</style>