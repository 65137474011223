var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "wrapper", staticClass: "service", attrs: { id: "service" } },
    [
      _c("div", { staticClass: "new-tips" }, [
        _vm._v("使用本服务之前，请先阅读"),
        _c(
          "span",
          [
            _c("router-link", { attrs: { to: "/userAgreement" } }, [
              _vm._v("《用户协议》")
            ])
          ],
          1
        ),
        _vm._v("继续使用本服务即表示您已经阅读并认可此协议")
      ]),
      _c(
        "van-pull-refresh",
        {
          attrs: {
            "pulling-text": "下拉查看历史消息",
            "loosing-text": "释放即可查看历史消息"
          },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        },
        _vm._l(_vm.record, function(item) {
          return _c("div", { staticStyle: { clear: "both" } }, [
            item.sender == "client"
              ? _c(
                  "div",
                  [
                    item.type == "text"
                      ? _c("chatClient", {
                          attrs: {
                            clientImg: _vm.clientImg,
                            content: item.content
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    item.type == "text"
                      ? _c("chatText", { attrs: { content: item.content } })
                      : _vm._e(),
                    item.type == "quick_reply"
                      ? _c("chatChoice", {
                          attrs: {
                            content: item.content,
                            extra: item.extra,
                            id: item.id
                          },
                          on: { getChoice: _vm.getChoice }
                        })
                      : _vm._e(),
                    item.type == "suggestion"
                      ? _c("chatProblem", {
                          attrs: {
                            content: item.content,
                            extra: item.extra,
                            id: item.id,
                            session: _vm.session
                          },
                          on: { getProblem: _vm.getProblem }
                        })
                      : _vm._e(),
                    item.type == "form"
                      ? _c("chatFrom", {
                          attrs: {
                            content: item.content,
                            extra: item.extra,
                            id: item.id
                          },
                          on: { getFromData: _vm.getFromData }
                        })
                      : _vm._e(),
                    item.type == "rich_text"
                      ? _c("chatLink", {
                          attrs: {
                            content: item.content,
                            extra: item.extra,
                            id: item.id,
                            session: _vm.session
                          }
                        })
                      : _vm._e(),
                    item.type == "knowledge_answer"
                      ? _c("chatAnswer", {
                          attrs: {
                            content: item.content,
                            extra: item.extra,
                            id: item.id,
                            session: _vm.session
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
          ])
        }),
        0
      ),
      _c("div", { staticStyle: { width: "100%", height: "1rem" } }),
      _vm.inputShow == "y" || _vm.inputShow == "Y"
        ? _c(
            "div",
            { staticClass: "send-box", attrs: { id: "send-box" } },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-field", {
                    attrs: { placeholder: "您可以问我问题哦～" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.onSend.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { on: { click: _vm.onJump } },
                [
                  _c("van-icon", {
                    attrs: { name: "plus", size: "22", color: "#0068FF" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "robot", on: { click: _vm.jump } }, [
        _vm._m(0),
        _vm._m(1)
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "robot-title" }, [
      _c("p", [_vm._v("智能核保")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "robot-img" }, [
      _c("img", { attrs: { src: require("../../assets/images/bot.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }