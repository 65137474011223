var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-box" }, [
    _c(
      "div",
      { staticClass: "chat-left" },
      [
        _c("router-link", { attrs: { to: { path: "/chatDetails" } } }, [
          _c("img", {
            attrs: { src: require("../../../src/assets/images/service.png") }
          })
        ])
      ],
      1
    ),
    _c("div", { staticClass: "chat-right" }, [
      _c("span", { staticClass: "chat" }, [
        _vm._v(" " + _vm._s(_vm.content) + " "),
        _vm.extra.links
          ? _c(
              "div",
              {
                staticStyle: { "text-align": "center", color: "#1675FF" },
                on: { click: _vm.jump }
              },
              [_vm._v(" " + _vm._s(_vm.extra.links[0].text) + " ")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }