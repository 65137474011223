<template>
  <div>

    <van-field
        readonly
        clickable
        name="picker"
        :value="value"
        :label="title"
        placeholder='请选择'
        @click="showPicker = true"
        input-align="right"
        :required="required"
        :rules= rules
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onConfirm"
          @cancel="showPicker=false"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Form ,Field,Popup} from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.use(Popup);
export default {
  name: 'popupshow',
  data() {
    return {
      value:"",
      showPicker:false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      rules:[]
    }
  },
  components: {

  },
  props: {
    title: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
    columns: '',
    val:null,
    required:{
      type:Boolean,
      default:false
    }
  },
  computed: {
  },
  watch: {

  },
  created() {

  },
  mounted() {
    this.value = this.val
    if(this.required){
      this.rules =[
        {
          required:true,
          trigger:'change',
          message: this.title+'不能为空'
        },

      ]
    }
  },
  methods: {
    checked(j){
      if(j<10)
        j='0'+j;
      return j
    },
    onConfirm(){
      let date = new Date(this.currentDate)
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      this.value=y+'-'+this.checked(m)+'-'+this.checked(d)
      this.showPicker = false
      this.$emit('getDatetimePicker',this.field,this.value)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
