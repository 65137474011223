import { render, staticRenderFns } from "./chat-text.vue?vue&type=template&id=3d0e0ba5&scoped=true&"
import script from "./chat-text.vue?vue&type=script&lang=js&"
export * from "./chat-text.vue?vue&type=script&lang=js&"
import style0 from "./chat-text.vue?vue&type=style&index=0&id=3d0e0ba5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d0e0ba5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/foryor-marketing_uam6/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d0e0ba5')) {
      api.createRecord('3d0e0ba5', component.options)
    } else {
      api.reload('3d0e0ba5', component.options)
    }
    module.hot.accept("./chat-text.vue?vue&type=template&id=3d0e0ba5&scoped=true&", function () {
      api.rerender('3d0e0ba5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/chat/chat-text.vue"
export default component.exports