<template>
  <div class="chat-box">
    <div class="chat-left"><router-link :to="{ path: '/chatDetails' }"><img src="../../../src/assets/images/service.png" /></router-link></div>
    <div class="chat-right">
      <span class="chat">
        <div class="choice-title">{{content}}</div>
        <div class="choice-list" v-if="!show">
            <div class="problem" v-for="item in suggestions">
                <div @click="onProblem(item)" >{{ item }}</div>
            </div>
          <div  class="refresh" @click="onRefresh"><img src="../../../src/assets/images/hyh.png">换一换</div>
        </div>
        <div class="choice-list" v-else>
          <div class="problem" v-for="item in suggestions">
                <div  style="color:#ccc">{{ item }}</div>
            </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import {messageRefresh} from "../../api";

export default {
  name: 'service',
  data() {
    return {
      suggestions:[],
      show:false
    }
  },
  props: {
    content:{
      type: String,
      default: '',
    },
    extra:{
      type: Object,
      default: {},
    },
    id:{
      type: Number,
      default: 0,
    },
    session:{
      type: String,
      default: '',
    },
  },
   components: {

   },
  created() {

  },
   mounted() {
    for(let i = 0; i < this.extra.suggestions.length; i++){
      this.suggestions.push(this.extra.suggestions[i][0])
    }
    this.show = this.extra.used
    console.log(this.suggestions)
   // this.suggestions = this.extra.suggestions
  },
  methods: {
    onProblem(val){
      this.show = true
      this.$emit('getProblem',val,this.id)
    },
    onRefresh(){
      let query={
        sessionId:this.session,
        messageId:this.id
      }
      messageRefresh(query).then((res)=>{
        console.log(res)
          this.suggestions = res
      })
    }

  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.chat-box{
  display: flex;
  margin-bottom: .4rem;
  .chat-left{
    width: .8rem;
    height: .8rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .chat-right{
    width: 75%;
    color: #131313;
    font-size: .24rem;
    margin-left: .24rem;
    .chat{
      width: 100%;
      background: #fff;
      display: inline-block;
      border-radius:0 .16rem .16rem .16rem;
      font-size: .28rem;
      margin-top: .1rem;
    }
    .choice-title{
      font-weight: bold;
      border-bottom: 1px solid rgba(151, 151, 151, .1);
      padding: .3rem .3rem .1rem .3rem;
      margin-bottom: .1rem;
      color: #131313;
    }
    .choice-list{
      .problem{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: .1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 .3rem;
        div{
          margin-top: .1rem;
          padding-bottom: .12rem;
          border-bottom: 1px solid rgba(151, 151, 151, 0.1);
          text-align: center;
          color: #1675FF;
          width: 100%;
        }

        .arrow{
          align-self: center;
        }
      }
      .refresh{
        width: 1rem;
        line-height: .6rem;
        text-align: center;
        color: #1675FF;
        margin: 0 auto;
        font-size: .24rem;
        img{
          width: .24rem;
          height: .24rem;
          display: inline-block;
          vertical-align: middle;
          margin-right: .04rem;
          float: left;
          margin-top:0.17rem;
        }
      }
    }
  }
}
</style>

