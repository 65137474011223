var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-box" }, [
    _c(
      "div",
      { staticClass: "chat-left" },
      [
        _c("router-link", { attrs: { to: { path: "/chatDetails" } } }, [
          _c("img", {
            attrs: { src: require("../../../src/assets/images/service.png") }
          })
        ])
      ],
      1
    ),
    _c("div", { staticClass: "chat-right" }, [
      _c("span", { staticClass: "chat" }, [
        _c("div", { staticClass: "choice-title" }, [
          _vm._v(_vm._s(_vm.content))
        ]),
        !_vm.show
          ? _c(
              "div",
              { staticClass: "choice-list" },
              [
                _vm._l(_vm.suggestions, function(item) {
                  return _c("div", { staticClass: "problem" }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.onProblem(item)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item))]
                    )
                  ])
                }),
                _c(
                  "div",
                  { staticClass: "refresh", on: { click: _vm.onRefresh } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../src/assets/images/hyh.png")
                      }
                    }),
                    _vm._v("换一换")
                  ]
                )
              ],
              2
            )
          : _c(
              "div",
              { staticClass: "choice-list" },
              _vm._l(_vm.suggestions, function(item) {
                return _c("div", { staticClass: "problem" }, [
                  _c("div", { staticStyle: { color: "#ccc" } }, [
                    _vm._v(_vm._s(item))
                  ])
                ])
              }),
              0
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }