<template>
  <div class="chat-box">
    <div class="chat-left"><router-link :to="{ path: '/chatDetails' }"><img src="../../../src/assets/images/service.png" /></router-link></div>
    <div class="chat-right">
      <span class="chat" @click="jump">
          {{text}}
      </span>
    </div>
  </div>
</template>

<script>
import {messageClickLink} from "../../api";

export default {
  name: 'service',
  data() {
    return {
      text:''
    }
  },
   components: {

   },
  props: {
    content:{
      type: String,
      default: '',
    },
    extra:{
      type: Object,
      default: {},
    },
    id:{
      type: Number,
      default: 0,
    },
    session:{
      type: String,
      default: '',
    },
  },
  created() {

  },
   mounted() {
    this.text = this.content || this.extra.links[0].text
  },
  methods: {
    jump(){
      let qurey={
        sessionId :this.session,
        messageId : this.id,
        link : this.extra.links[0].url
      }
      messageClickLink(qurey).then((res)=>{
        console.log(this.extra.links[0].url)
        this.$router.push({path:this.extra.links[0].url});
       // window.location.href = this.extra.links[0].url
      })
    }

  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.chat-box{
  display: flex;
  margin-bottom: .4rem;
  .chat-left{
    width: .8rem;
    height: .8rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .chat-right{
    width: 75%;
    color: #131313;
    font-size: .24rem;
    margin-left: .24rem;
    .chat{
      background: #fff;
      display: inline-block;
      padding: .24rem;
      border-radius:0 .16rem .16rem .16rem;
      font-size: .28rem;
      margin-top: .1rem;
      color: #1675FF;
      line-height: .45rem;
    }
  }
}
</style>

