<template>
  <div class="chat-box">
    <div class="chat-left"><img src="../../../src/assets/images/service.png" /></div>
    <div class="chat-right">
      <span class="chat">
        <div class="choice-title">术后疾病管理方案</div>
        <div class="choice-center">
            <div class="plan-img"><img src="../../../src/assets/images/service-01.png" /></div>
        </div>
        <div class="more">点击查看详情</div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'service',
  data() {
    return {

    }
  },
   components: {

   },
  created() {

  },
   mounted() {

  },
  methods: {
    onChoice(){

    }

  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.chat-box{
  display: flex;
  .chat-left{
    width: .8rem;
    height: .8rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .chat-right{
    width: 75%;
    color: #131313;
    font-size: .24rem;
    margin-left: .24rem;
    .chat{
      width: 100%;
      background: #fff;
      display: inline-block;
      border-radius: .16rem;
      font-size: .28rem;
      margin-top: .1rem;
    }
    .choice-title{
      font-weight: bold;
      border-bottom: 1px solid rgba(151, 151, 151, .1);
      padding: .3rem .3rem .1rem .3rem;
      margin-bottom: .1rem;
    }
    .choice-center{
      padding: .3rem;
      border-bottom: 1px solid rgba(151, 151, 151, .1);
      .plan-img{
        text-align: center;
        margin-bottom: .3rem;
        img{
          width: 1.8rem;
          height: 1.68rem;
        }
      }
    }
    .more{
      text-align: center;
      color: #0068FF;
      padding: .24rem;
    }
  }
}
</style>

