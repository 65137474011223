<template>
  <div class="service" id="service" ref="wrapper">
    <div class="new-tips">使用本服务之前，请先阅读<span><router-link to='/userAgreement'>《用户协议》</router-link></span>继续使用本服务即表示您已经阅读并认可此协议</div>
<!--    <chatFrom-->
<!--        @getFromData="getFromData"/>-->
    <van-pull-refresh v-model="isLoading" pulling-text="下拉查看历史消息" loosing-text="释放即可查看历史消息" @refresh="onRefresh">
      <div v-for="item in record"  style="clear: both">
      <div v-if="item.sender=='client'">
        <chatClient v-if="item.type == 'text'" :clientImg="clientImg" :content="item.content" />
      </div>
      <div v-else>
        <chatText v-if="item.type == 'text'" :content="item.content" />
        <chatChoice v-if="item.type == 'quick_reply'" :content="item.content" :extra="item.extra" :id="item.id" @getChoice="getChoice" />
        <chatProblem
            v-if="item.type == 'suggestion'"
            :content="item.content"
            :extra="item.extra"
            :id="item.id"
            :session="session"
            @getProblem="getProblem"/>
        <chatFrom
            v-if="item.type == 'form'"
            :content="item.content"
            :extra="item.extra"
            :id="item.id"
            @getFromData="getFromData"/>
        <chatLink
            v-if="item.type == 'rich_text'"
           :content="item.content"
           :extra="item.extra"
           :id="item.id"
            :session="session"/>
        <chatAnswer
            v-if="item.type == 'knowledge_answer'"
            :content="item.content"
            :extra="item.extra"
            :id="item.id"
            :session="session"/>
      </div>
    </div>
    </van-pull-refresh>
    <div style="width: 100%; height: 1rem"></div>
    <div class="send-box" id="send-box"  v-if="inputShow == 'y' || inputShow == 'Y'">
      <van-cell-group>
        <van-field v-model="value"  @keyup.enter.native="onSend" placeholder="您可以问我问题哦～" />
      </van-cell-group>
      <div @click="onJump"><van-icon name="plus"  size="22" color="#0068FF" /></div>
    </div>
    <div class="robot" @click="jump">
      <div class="robot-title"><p>智能核保</p></div>
      <div class="robot-img"><img src="../../assets/images/bot.png"> </div>
    </div>
  </div>
</template>

<script>
 import { newSession,sessionList,questionCate,enumList,getCategory,getSolve,formSubmit} from '@/api'
 import { setCookie } from '@/util/util'
import Vue from 'vue'
import { Form ,Field,Button,DatetimePicker,Popup,RadioGroup, Radio,Toast,Loading,PullRefresh} from 'vant';
Vue.use(Form);
Vue.use(Toast);
Vue.use(Loading)
Vue.use(PullRefresh)
//import BScroll from '@better-scroll/core'
import chatText from './chat-text'
import chatChoice from './chat-choice'
import chatPlan from './chat-plan'
import chatDetails from './chat-details'
import chatVideo from './chat-video'
import chatProblem from './chat-problem'
import chatFrom from './chat-from'
 import chatClient from  './chat-client'
 import chatLink from './chat-link'
 import chatAnswer from './chat-answer'
 import {sessionIcon} from "../../api";
import imgIcon from '../../assets/images/header1.png'
export default {
  name: 'service',
  data() {
    return {
      path:'',
      rollHeight:"",
      sendHeight:"",
      maxChatLogId:"",
      pageSize:10,
      currentPage:1,
      session:"",
      record:[],
      query:{},
      value:"",
      isLoading:false,
      inputShow:'y',
      clientImg:"",
      timeout: 1000,//30秒一次心跳
      timeoutObj: null,//心跳心跳倒计时
      serverTimeoutObj: null,//心跳倒计时
      timeoutnum: null,//断开 重连倒计时
      lockReconnect: false,//是否真正建立连接
      timer:null,
      setIntervalWesocketPush:null
    }
  },
   components: {
     chatText,
     chatChoice,
     chatPlan,
     chatDetails,
     chatVideo,
     chatProblem,
     chatFrom,
     chatClient,
     chatLink,
     chatAnswer
   },
  created() {
    //获取域名
    // let protocol= ''
    // if(document.location.protocol == 'http:'){
    //   protocol= 'ws://'
    // }else {
    //   protocol= 'wss://'
    // }
    // if(sessionStorage.getItem('routepath')){
    //   this.path = protocol+document.location.hostname+'/chat?backFromPage='+sessionStorage.getItem('routepath')
    // }else {
    //   this.path = protocol+document.location.hostname+'/chat'
    // }
    // if(sessionStorage.getItem('routepath')){
    //   this.path = 'wss://agency.foryor.com/chat?backFromPage='+sessionStorage.getItem('routepath')
    // }else {
    //   //this.path = 'ws://agency-test.foryor.com/chat'
    //   this.path = 'wss://agency.foryor.com/chat'
    // }
  },
   mounted() {
     if(this.$route.query.marketingToken){
       this.session = this.$route.query.session
       this.$store.commit('SET_TOKEN', this.$route.query.marketingToken)
       this.$store.commit('SET_SESSION', this.$route.query.session)
       setCookie('session',this.$route.query.session)
       this.init();
       this.getsessionList()
       this.getIcon()
     }else {
       if(this.$store.getters.session){
         this.session = this.$store.getters.session
         this.init();
         this.getsessionList()
         this.getIcon()
       }else{
         this.getnewSession()
       }
     }
  },
  methods: {
//获取session数据
    getnewSession(){
      newSession().then((res) => {
        this.session = res
        this.init();
        this.getsessionList()
        this.getIcon()
        this.$store.commit('SET_SESSION', res)
        setCookie('session',res)
      })
    },
    //初始化
    init() {
      let protocol= ''
      if(document.location.protocol == 'http:'){
        protocol= 'ws://'
      }else {
        protocol= 'wss://'
      }
      if(sessionStorage.getItem('routepath')){
        this.path = protocol+document.location.hostname+'/chat?backFromPage='+sessionStorage.getItem('routepath')
        //this.path = 'wss://agency.foryor.com/chat?backFromPage='+sessionStorage.getItem('routepath')
      }else {
        this.path = protocol+document.location.hostname+'/chat'
        //this.path = 'ws://agency-test.foryor.com/chat'
       // this.path = 'wss://agency.foryor.com/chat'
      }
      console.log('chonglian')
      if(typeof(WebSocket) === "undefined"){
        alert("您的浏览器不支持socket")
      }else{
        // 实例化socket
        // var vm =this;
        // var token = vm.session
       // clearInterval(this.timer);
        this.socket = new WebSocket(this.path,this.session);
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket错误信息
        this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage
      }
    },
    //心跳包
    sendPing(){
      clearInterval(this.setIntervalWesocketPush)
      this.socket.send('ping')
      this.setIntervalWesocketPush = setInterval(() => {
        this.socket.send('ping')
      }, 5000)
    },
    getIcon(){
      sessionIcon(this.session).then((res)=>{
        console.log(res)
        if(res){
          this.clientImg = res
        }else {
          this.clientImg = imgIcon
        }
      })
    },
    open() {
     // sessionStorage.removeItem('routepath')
      console.log("socket连接成功")
      let send = sessionStorage.getItem('send')
     // console.log(send)
     // this.start()
      if(send){
        this.send();
      }
    },

    close: function () {
      this.socket.close();
      console.log("socket已经关闭")
    },
    error() {
      this.init()
      // Toast('连接错误,请推出页面重新进入')
      console.log("连接错误")
    },
    getMessage(msg) {
      //接收
      // console.log(msg)
      let all = JSON.parse(msg.data)
      if(all.type == 'input_status'){
        this.inputShow = all.extra.userInputType
      }
      this.record.push(all)
      //滚动到页面最底部
      this.$nextTick(() => {
        var p = document.getElementById('service');
        // console.log(this.sendHeight)
        p.scrollTop = this.sendHeight-100;
        this.rollHeight = p.scrollHeight
        //console.log('聊天时高度：'+this.rollHeight)
      })
    },
    //获取聊天记录
    getsessionList(){
      sessionList(this.session,this.maxChatLogId,this.pageSize,this.currentPage).then((res) => {
        this.record = res
        let length = res.length-1
        this.maxChatLogId = res[length].id
        //滚动到页面最底部
        this.$nextTick(() => {
          var p = document.getElementById('service');
          //console.log('qq'+p.scrollHeight)
          p.scrollTop = p.scrollHeight;
          this.rollHeight = p.scrollHeight
          this.sendHeight= p.scrollHeight+150;
          // console.log('第一次聊天记录高度：'+this.rollHeight)
        })
      })
    },
    send () {
      if( this.socket.readyState !=  this.socket.OPEN){
        sessionStorage.setItem('send','on');
        sessionStorage.setItem("routepath", 'index')
        this.init()
      }else{
        console.log(this.record)
        this.record.push(this.query)
        sessionStorage.removeItem('send')
        this.socket.send(JSON.stringify(this.query))
        this.query ={}
        this.$nextTick(() => {
          var p = document.getElementById('service');
          this.sendHeight= p.scrollHeight;
            // console.log('fa:'+this.sendHeight)
           this.rollHeight = p.scrollHeight
          // console.log('聊天时高度：'+this.rollHeight)
        })
      }
    },
    //获取各类值
    getChoice(content,id){
      this.query={
        "type" : "text",
        "content" : content,
        "extra" : {
          "usedQuickReplyId":id // 被点击的bot消息的id字段
        },
        "sender":'client'
      }
      this.send()
    },
    getProblem(content,id){
      this.query={
        "type" : "text",
        "content" : content,
        "extra" : {
          "usedSuggestionId":id // 被点击的bot消息的id字段
        },
        "sender":'client'
      }
      this.send()
    },
    getFromData(val,id){
      if( this.socket.readyState !=  this.socket.OPEN){
       // sessionStorage.setItem('send','on');
        sessionStorage.setItem("routepath",'index')
        this.init()
      }
      formSubmit(this.session,id,val).then((res)=>{
        console.log(val)
      })
    },
    onSend(){
      this.query={
        "type" : "text",
        "content" : this.value,
        "sender":'client'
      }
      this.send()
      this.value = ''
    },
    onJump(){
      this.$router.push({ path: '/chatUpload', query: { session:this.session } })
    },
    //分页
    onRefresh(){
      if(this.maxChatLogId == ''){
        Toast('当前已是全部数据～')
        this.isLoading = false;
        return
      }
      sessionList(this.session,this.maxChatLogId,this.pageSize,this.currentPage+1).then((res) => {
        this.isLoading = false;
        if(res.length!=0){
          let length = res.length-1
          this.maxChatLogId = res[length].id
          this.record = [...res,...this.record]
          this.$nextTick(() => {
            var h = document.getElementById('service');
            // console.log('当前高度：'+h.scrollHeight)
            h.scrollTop = h.scrollHeight - this.rollHeight
            // console.log('滚动高度：'+this.roll)
            // this.rollHeight= h.scrollHeight
          })

        }
      })

    },
    jump(){
      if(this.$store.getters.token){
        this.$router.push({path:'/caseList'});
      }else {
        this.$router.push({path:'/login'});
      }
    }

  },
   destroyed () {

    },

  updated(){

  },
  beforeRouteEnter(to, from, next) {
    next(vm=>{          //  这里的vm指的就是vue实例，可以用来当做this使用
      // console.log(to)
     // console.log(from.path)
      if(from.path && from.path !='/'){
        sessionStorage.setItem("routepath", from.path)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.service{
  width: 100%;
  height: 100%;
  background: #F3F4FC;
  position: fixed;
  left: 0;
  top: 0;
  overflow: scroll;
  font-size: .28rem;
  padding:.3rem;
  .new-tips{
    width: 85%;
    padding: .2rem .4rem;
    background:rgba(0,0,0,0.2);
    border-radius: .76rem;
    margin: 0 auto;
    font-size: .22rem;
    color: #fff;
    margin-bottom: .4rem;
    line-height: .38rem;
    span{
      color: #0068FF;
    }
  }
  .send-box{
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    align-items: center;
    justify-content: space-between;
    padding: .24rem .3rem;

  }
}
.robot{
  position: fixed;
  right: .3rem;
  bottom: 2.8rem;
  text-align: center;
  font-weight: 500;
  color: #0068FF;
  font-size: .24rem;
  img{
    width: .68rem;
    height: auto;
  }
}
</style>
<style>
#send-box .van-cell-group{
  width: 88%;
}
#send-box .van-cell {
  width: 100%;
  background: #F2F3F7;
  border-radius: .6rem;
  top: 0;
}
#send-box .van-hairline--top-bottom{
  position:unset;
}

</style>

