<template>
  <div class="chat-box">
    <div class="chat-left">
      <span class="chat">
       {{content}}
      </span>
    </div>
    <div class="chat-right"><img :src="clientImg" /></div>
  </div>
</template>

<script>

export default {
  name: 'service',
  data() {
    return {

    }
  },
   components: {

   },
  props: {
    content:{
      type: String,
      default: '',
    },
    clientImg:{
      type: String,
      default: '',
    }

  },
  created() {

  },
   mounted() {

  },
  methods: {


  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.chat-box{
  width: 100%;
  display: flex;
  margin-bottom: .4rem;
  float: right;
  .chat-right{
    width: .8rem;
    height: .8rem;
    img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .chat-left{
    width: 85%;
    color: #131313;
    font-size: .24rem;
    margin-right: .24rem;
    .chat{
      background: #0068FF;
      display: inline-block;
      padding: .24rem;
      border-radius:.16rem;
      font-size: .28rem;
      margin-top: .1rem;
      color: #fff;
      float: right;
    }
  }
}
</style>

