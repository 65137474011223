var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-box" }, [
    _c("div", { staticClass: "chat-left" }, [
      _c("span", { staticClass: "chat" }, [
        _vm._v(" " + _vm._s(_vm.content) + " ")
      ])
    ]),
    _c("div", { staticClass: "chat-right" }, [
      _c("img", { attrs: { src: _vm.clientImg } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }