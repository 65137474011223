<template>
  <div>
    <van-field name="radio" :label="title" input-align="right" :required="required" :rules= rules>
      <template #input>
        <van-checkbox-group v-model="value" direction="horizontal"  @change="onConfirm" >
          <van-checkbox  shape="square"  v-for="group in columns" icon-size="14px" :name=group.value>{{group.value}}</van-checkbox>
        </van-checkbox-group>
      </template>
    </van-field>
  </div>
</template>

<script>
import Vue from 'vue'
import { Form ,Field,Popup} from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.use(Popup);
export default {
  name: 'popupshow',
  data() {
    return {
      value:"",
      showPicker:false,
      rules:[],
      radio:"1"
    }
  },
  components: {

  },
  props: {
    title: {
      type: String,
      default: '',
    },
    field:{
      type: String,
      default: '',
    },
    columns: '',
    val:null,
    required:{
      type:Boolean,
      default:false
    }
  },
  computed: {
  },
  watch: {

  },
  created() {

  },
  mounted() {
    this.value = this.val
    if(this.required){
      this.rules =[
        {
          required:true,
          trigger:'change',
          message: this.title+'不能为空'
        },

      ]
    }
  },
  methods: {
    onConfirm(){
      this.$emit('getVanCheckbox',this.field,this.value)
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
<style>
.van-radio--horizontal{
  margin-right: 0;
  margin-left: 2px;
}
.radio-group{
  display: flex;
  justify-content:flex-end!important;
}
.van-field__label{
  width: 4em;
}
.van-cell__value{
  width: 12.5em;
}
.van-radio__label{
  margin-left: 3px;
}
.van-checkbox-group--horizontal{
  display: flex;
  justify-content:flex-end;
}
.van-checkbox--horizontal{
  margin-right: 0;
  margin-left: 5px;
}
.van-checkbox__label{
  margin-left: 5px;
}
</style>