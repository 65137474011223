var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "chat-box" }, [
      _c("div", { staticClass: "chat-left" }, [
        _c("img", {
          attrs: { src: require("../../../src/assets/images/service.png") }
        })
      ]),
      _c("div", { staticClass: "chat-right" }, [
        _c("span", { staticClass: "chat" }, [
          _c("div", { staticClass: "choice-title" }, [
            _vm._v("术后疾病管理方案")
          ]),
          _c("div", { staticClass: "choice-center" }, [
            _c("div", { staticClass: "plan-img" }, [
              _c("img", {
                attrs: {
                  src: require("../../../src/assets/images/service-01.png")
                }
              })
            ])
          ]),
          _c("div", { staticClass: "more" }, [_vm._v("点击查看详情")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }