var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-box" }, [
    _c(
      "div",
      { staticClass: "chat-left" },
      [
        _c("router-link", { attrs: { to: { path: "/chatDetails" } } }, [
          _c("img", {
            attrs: { src: require("../../../src/assets/images/service.png") }
          })
        ])
      ],
      1
    ),
    _c("div", { staticClass: "chat-right" }, [
      _c("span", { staticClass: "chat", attrs: { id: "chat-box" } }, [
        _c("div", { staticClass: "choice-title" }, [
          _vm._v(_vm._s(_vm.content))
        ]),
        _c(
          "div",
          [
            _c(
              "van-form",
              { ref: "rules", on: { submit: _vm.onSubmit } },
              [
                _c(
                  "van-cell-group",
                  { attrs: { inset: "" } },
                  _vm._l(_vm.rule, function(item) {
                    return _c("div", [
                      item.type == "datePicker"
                        ? _c(
                            "div",
                            [
                              item.validate
                                ? _c("datetimePicker", {
                                    attrs: {
                                      title: item.title,
                                      required: item.validate[0].required,
                                      field: item.field,
                                      val: item.value
                                    },
                                    on: {
                                      getDatetimePicker: _vm.getDatetimePicker
                                    }
                                  })
                                : _c("datetimePicker", {
                                    attrs: {
                                      title: item.title,
                                      required: false,
                                      field: item.field,
                                      val: item.value
                                    },
                                    on: {
                                      getDatetimePicker: _vm.getDatetimePicker
                                    }
                                  })
                            ],
                            1
                          )
                        : _vm._e(),
                      item.type == "select"
                        ? _c(
                            "div",
                            [
                              item.validate
                                ? _c("vanField", {
                                    attrs: {
                                      title: item.title,
                                      field: item.field,
                                      required: item.validate[0].required,
                                      columns: item.options,
                                      val: item.value
                                    },
                                    on: { getVanfield: _vm.getVanfield }
                                  })
                                : _c("vanField", {
                                    attrs: {
                                      title: item.title,
                                      field: item.field,
                                      required: false,
                                      columns: item.options,
                                      val: item.value
                                    },
                                    on: { getVanfield: _vm.getVanfield }
                                  })
                            ],
                            1
                          )
                        : _vm._e(),
                      item.type == "radio"
                        ? _c(
                            "div",
                            [
                              item.validate
                                ? _c("vanRadio", {
                                    attrs: {
                                      title: item.title,
                                      field: item.field,
                                      required: item.validate[0].required,
                                      columns: item.options,
                                      val: item.value
                                    },
                                    on: { getVanRadio: _vm.getVanRadio }
                                  })
                                : _c("vanRadio", {
                                    attrs: {
                                      title: item.title,
                                      field: item.field,
                                      required: false,
                                      columns: item.options,
                                      val: item.value
                                    },
                                    on: { getVanRadio: _vm.getVanRadio }
                                  })
                            ],
                            1
                          )
                        : _vm._e(),
                      item.type == "checkbox"
                        ? _c(
                            "div",
                            [
                              item.validate
                                ? _c("vanCheckox", {
                                    attrs: {
                                      title: item.title,
                                      field: item.field,
                                      required: item.validate[0].required,
                                      columns: item.options,
                                      val: item.value
                                    },
                                    on: { getVanCheckbox: _vm.getVanCheckbox }
                                  })
                                : _c("vanCheckox", {
                                    attrs: {
                                      title: item.title,
                                      field: item.field,
                                      required: false,
                                      columns: item.options,
                                      val: item.value
                                    },
                                    on: { getVanCheckbox: _vm.getVanCheckbox }
                                  })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  }),
                  0
                ),
                _vm.btnShow
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          margin: "16px auto",
                          "text-align": "center"
                        }
                      },
                      [
                        _c(
                          "van-button",
                          {
                            staticStyle: { width: "130px", margin: "0 auto" },
                            attrs: {
                              round: "",
                              block: "",
                              type: "primary",
                              color: "#0068FF",
                              "native-type": "submit"
                            }
                          },
                          [_vm._v(" 提交 ")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }