var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-field", {
        attrs: {
          readonly: "",
          clickable: "",
          name: "picker",
          value: _vm.value,
          label: _vm.title,
          placeholder: "请选择",
          "input-align": "right",
          required: _vm.required,
          rules: _vm.rules
        },
        on: {
          click: function($event) {
            _vm.showPicker = true
          }
        }
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPicker,
            callback: function($$v) {
              _vm.showPicker = $$v
            },
            expression: "showPicker"
          }
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              "value-key": "value",
              columns: _vm.columns
            },
            on: {
              confirm: _vm.onConfirm,
              cancel: function($event) {
                _vm.showPicker = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }