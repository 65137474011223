var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "chat-box" }, [
      _c("div", { staticClass: "chat-left" }, [
        _c("img", {
          attrs: { src: require("../../../src/assets/images/service.png") }
        })
      ]),
      _c("div", { staticClass: "chat-right" }, [
        _c("p", [_vm._v("佳佳")]),
        _c("span", { staticClass: "chat" }, [_c("video")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }