<template>
  <div class="chat-box">
    <div class="chat-left"><router-link :to="{ path: '/chatDetails' }"><img src="../../../src/assets/images/service.png" /></router-link></div>
    <div class="chat-right">
      <span class="chat">
       {{content}}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'service',
  data() {
    return {

    }
  },
   components: {

   },
  props: {
    content:{
      type: String,
      default: '',
    },
  },
  created() {

  },
   mounted() {

  },
  methods: {


  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.chat-box{
  display: flex;
  margin-bottom: .4rem;
  .chat-left{
    width: .8rem;
    height: .8rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .chat-right{
    width: 75%;
    color: #131313;
    font-size: .24rem;
    margin-left: .24rem;
    .chat{
      background: #fff;
      display: inline-block;
      padding: .24rem;
      border-radius:0 .16rem .16rem .16rem;
      font-size: .28rem;
      margin-top: .1rem;
      line-height: .45rem;
    }
  }
}
</style>

