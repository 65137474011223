<template>
  <div class="chat-box">
    <div class="chat-left"><router-link :to="{ path: '/chatDetails' }"><img src="../../../src/assets/images/service.png" /></router-link></div>
    <div class="chat-right">
      <span class="chat" id="chat-box">
         <div class="choice-title">{{content}}</div>
          <div>
            <van-form @submit="onSubmit" ref="rules">
                <van-cell-group inset>
                  <div v-for="item in rule" >
                    <div v-if="item.type=='datePicker'">
                      <datetimePicker  :title="item.title" v-if="item.validate" :required="item.validate[0].required" :field="item.field" :val="item.value" @getDatetimePicker="getDatetimePicker" />
                       <datetimePicker  :title="item.title" v-else :required="false" :field="item.field" :val="item.value" @getDatetimePicker="getDatetimePicker" />
                    </div>
                    <div v-if="item.type == 'select'">
                      <vanField :title="item.title" :field="item.field" v-if="item.validate"  :required="item.validate[0].required"  :columns="item.options" :val="item.value" @getVanfield="getVanfield" />
                      <vanField :title="item.title" :field="item.field" v-else :required="false"  :columns="item.options" :val="item.value" @getVanfield="getVanfield" />
                    </div>
                    <div v-if="item.type=='radio'">
                      <vanRadio :title="item.title" :field="item.field"  v-if="item.validate"  :required="item.validate[0].required"  :columns="item.options" :val="item.value" @getVanRadio="getVanRadio" />
                      <vanRadio :title="item.title" :field="item.field"   v-else :required="false"  :columns="item.options" :val="item.value" @getVanRadio="getVanRadio" />
                    </div>
                    <div v-if="item.type=='checkbox'">
                      <vanCheckox :title="item.title" :field="item.field"  v-if="item.validate"  :required="item.validate[0].required"  :columns="item.options" :val="item.value" @getVanCheckbox="getVanCheckbox" />
                      <vanCheckox :title="item.title" :field="item.field"   v-else :required="false"  :columns="item.options" :val="item.value" @getVanCheckbox="getVanCheckbox" />
                    </div>
                  </div>
                </van-cell-group>
                <div style="margin: 16px auto; text-align: center" v-if="btnShow">
                  <van-button round block type="primary" color="#0068FF" style="width: 130px; margin: 0 auto" native-type="submit">
                    提交
                  </van-button>
                </div>
              </van-form>
          </div>

<!--         <form-create v-model="fApi" :rule="extra.formDesign.rule"  :option="option"></form-create>-->
<!--        <form-create v-model="fApi" :rule="rule"  :option="option"></form-create>-->
      </span>
    </div>
  </div>
</template>

<script>
import vanField from '../form/vanField'
import datetimePicker from "../form/datetimePicker";
import vanRadio from "../form/vanRadio";
import vanCheckox from "../form/vanCheckox";
import Vue from 'vue';
import {Form ,Field,Button,DatetimePicker,Popup,RadioGroup, Radio,Toast,Overlay,Icon,Dialog} from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Overlay);
Vue.use(Icon);
export default {
  name: 'service',
  data() {
    return {
      formData:{},
      fApi:{},
      options:{},
      btnShow:true,
      rule:[],
    }
  },
  props: {
    content:{
      type: String,
      default: '',
    },
    extra:{
      type: Object,
      default: {},
    },
    id:{
      type: Number,
      default: 0,
    }
  },
  watch:{
    extra: {
      handler: function() {
        if(this.extra.submitData){
          for(let i= 0; i <  this.rule.length; i++){
            for(let j in  this.extra.submitData){
              if(this.rule[i].field == j){
                this.rule[i].value =this.extra.submitData[j]
              }
            }
          }
          this.$forceUpdate();
        }
      },
      deep: true
    }
  },
  components:{
    vanField,
    datetimePicker,
    vanRadio,
    vanCheckox
  },
  created() {

  },
  mounted() {
    if(this.extra.submitTime){
      this.btnShow = false
    }
    console.log(this.extra.formDesign.rule)
    this.rule = this.extra.formDesign.rule
    if(this.extra.submitData){
      for(let i= 0; i <  this.rule.length; i++){
        for(let j in  this.extra.submitData){
          if(this.rule[i].field == j){
            this.rule[i].value =this.extra.submitData[j]
          }
        }
      }
      this.$forceUpdate();
    }
    console.log( this.rule)
  },
  methods: {
    getDatetimePicker(field,val){
        this.rule.filter(item => item.field=== field)[0].value =val
    },
    getVanfield(field,val){
      this.rule.filter(item => item.field=== field)[0].value =val
    },
    getVanRadio(field,val){
      console.log(field,val)
      this.rule.filter(item => item.field=== field)[0].value =val
    },
    getVanCheckbox(field,val){
      this.rule.filter(item => item.field=== field)[0].value =val
    },
    getRadio(field,val){
      this.$forceUpdate();
    },
    onSubmit(){
      this.$refs.rules.validate().then(()=>{
        // 验证通过
        this.rule.forEach(item => {
          let key = item.field;
          this.formData[key] = item.value;
        })
        this.btnShow = false
        this.$emit('getFromData',this.formData,this.id)
      }).catch(()=>{
        //验证失败
        console.log('2')
      })

    }
  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.chat-box{
  display: flex;
  touch-action: pan-y;
  margin-bottom: .3rem;
  .chat-left{
    width: .8rem;
    height: .8rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .chat-right{
    width: 75%;
    color: #131313;
    font-size: .24rem;
    margin-left: .24rem;
    .chat{
      width: 100%;
      background: #fff;
      display: inline-block;
      border-radius: .16rem;
      font-size: .28rem;
      margin-top: .1rem;
    }
    .choice-title{
      font-weight: bold;
      border-bottom: 1px solid rgba(151, 151, 151, .1);
      padding: .3rem .3rem .1rem .3rem;
      margin-bottom: .1rem;
    }
    .choice-list{
      div{
        text-align: center;
        line-height: .6rem;
        color: #0068FF;
      }
    }
  }
  .radio-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #646566;
    font-size: 14px;
  }
}
</style>
<style>

.van-radio--horizontal{
  margin-right: 0;
  margin-left: 5px;
}
.van-checkbox-group--horizontal{
  display: flex;
  justify-content:flex-end;
}
.van-checkbox--horizontal{
  margin-right: 0;
  margin-left: 5px;
}
.van-cell--required::before{
  left: 0;
}
.van-cell{
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);
}
.van-cell::after{
  border: none;
}
[class*=van-hairline]::after{
  border: none;
}
</style>
