<template>
  <div class="chat-box">
    <div class="chat-left"><router-link :to="{ path: '/chatDetails' }"><img src="../../../src/assets/images/service.png" /></router-link></div>
    <div class="chat-right">
      <span class="chat">
        <div class="choice-title" v-if="content && content!=''">{{content}}</div>
        <div class="choice-list" v-if="!show">
            <div  @click="onChoice(item[0])" v-for="item in extra.choices">
             {{item[0]}}
            </div>
        </div>
        <div class="choice-list" v-else>
          <div style="color:#ccc"  v-for="item in extra.choices">
             {{item[0]}}
            </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'service',
  data() {
    return {
      show:false
    }
  },
   components: {

   },
  props: {
    content:{
      type: String,
      default: '',
    },
    extra:{
      type: Object,
      default: {},
    },
    id:{
      type: Number,
      default: 0,
    }
  },
  created() {

  },
   mounted() {
      this.show = this.extra.used
  },
  methods: {
    onChoice(val){
      this.show = true
      this.$emit('getChoice',val,this.id)
    }

  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.chat-box{
  display: flex;
  margin-bottom: .4rem;
  .chat-left{
    width: .8rem;
    height: .8rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .chat-right{
    width: 75%;
    color: #131313;
    font-size: .24rem;
    margin-left: .24rem;
    .chat{
      width: 100%;
      background: #fff;
      display: inline-block;
      border-radius: 0 .16rem .16rem .16rem;
      font-size: .28rem;
      margin-top: .1rem;
    }
    .choice-title{
      font-weight: 400;
      border-bottom: 1px solid rgba(151, 151, 151, .1);
      padding: .3rem .3rem .1rem .3rem;
      margin-bottom: .1rem;
      color: #131313;
    }
    .choice-list{
      padding: 0 .3rem;
      div{
        text-align: center;
        line-height: .8rem;
        color: #0068FF;
        border-bottom: 1px solid rgba(151, 151, 151, 0.1);
      }
      :last-child{
        border-bottom: none;
      }
    }
  }
}
</style>

